/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~firebaseui/dist/firebaseui.css';

@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;500&display=swap');

ion-content,
ion-accordion {
  --background: #fcfcfc00;
  background-color: #fcfcfc00;
  --ion-background-color: rgba(255, 255, 255, 0);
  --ion-color-base: rgb(241 244 247 / 88%);
}

.ion-page {
  --background: #e9f0f7;
}
// TODO: This colours should be centralized and used as @use from scss, but i haven't been able to fix
// the typescripts paths yet to use it properly Dx
// This background color is repeated, so be sure to replace it when scss is fixed.
ion-item {
  --background: rgb(241 244 247 / 88%);
}

ion-item.item-input.item-label-floating.item-has-focus,
ion-item.item-input.item-label-floating.item-has-value {
  ion-label.label-floating {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    border-radius: 6px;
    background: #eef2f6;
  }
}

.improv-center {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
}

.improvised-button {
  width: 220px;
}

.i-m-t-1 {
  margin-top: 80px;
}

.i-m-t-2 {
  margin-top: 30px;
}

.i-m-1 {
  margin-bottom: 40px;
}

.i-m-2 {
  margin-bottom: 30px;
}

.i-m-3 {
  margin-bottom: 20px;
}

hr.linea {
  width: 200px;
  background-color: #dcd7d7;
}

.dc-back-arrow {
  margin-right: 2%;
  padding: 5px;
  border-radius: 90px;

  &:hover {
    background-color: #23a03e;
    color: #fcfcfc;
  }
}

docheck-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.ion-segment {
  justify-content: space-around;
}

.menu-text {
  font-size: 10px;
  margin-top: 0;
}

#firebaseui-auth-container {
  min-height: 300px;

  .firebaseui-container,
  .mdl-card {
    background-color: rgba($color: #000000, $alpha: 0);
  }

  .mdl-shadow--2dp {
    box-shadow: none;
  }

  .firebaseui-card-actions {
    padding-bottom: 0px;
  }

  .firebaseui-card-footer {
    margin-top: 15px;
  }

  .firebaseui-tos {
    color: #32343a;
  }
}

ion-datetime {
  color: var(--ion-color-medium);
}

/* Custom class for our modals */
@mixin modal-content-ma-all-around {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.41);

  width: 85dvw;
  height: 80dvh;
  max-width: 600px;
  max-height: 800px;
  position: absolute;
  inset: 0;
  margin: auto;

  overflow: auto;

  background: rgba(255, 255, 255, 0.65);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.8px);
  -webkit-backdrop-filter: blur(5.8px);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

ion-modal.ma-ion-modal {
  &::part(content) {
    background-color: #0000001e;
  }

  app-search-options.ion-page,
  app-rut-search-modal.ion-page,
  typeahead.ion-page {
    @include modal-content-ma-all-around();

    ion-content::part(scroll) {
      background-color: #ffffff;
    }
  }
}

// This is not repeated, its here to override ionic's default modal size
@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal.ma-ion-modal {
    --height: 100%;
    --width: 100%;
    app-search-options.ion-page,
    app-rut-search-modal.ion-page,
    typeahead.ion-page {
      @include modal-content-ma-all-around();
    }
  }
}
